*{
  font-family: 'courier', open-sans, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-weight: 'lighter';
}
html,body {
  background: rgb(193,213,239);
background: linear-gradient(180deg, rgb(255, 255, 255) 0%, #fefffc 14%, #eaffd1 57%, #849F62 83%);
  background-color: #ffffff;
  background-attachment: scroll;
  background-size: cover;
  margin: 0;
  min-height: 100vh;
  overflow: auto;
  
    background-color: #FFFFFF;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
li{
  list-style-type: none;
  text-decoration: none;
}
.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}

.navbar {
  background-color: #fef7e5;
  position: relative;
}

.logo {
}

.menu-icon {
  display: none;
}

.nav-elements {
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: #2f234f;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

@media (max-width: 600px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    z-index: 3;
    position: absolute;
    right: 0;
    top: 60px;
    background-color: #fef7e5;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 70vw;
    border-radius: 10%;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
    Width: '30vw';
    max-height: '10vh';
    display: 'flex';
    flex-direction: 'row';
    align-items: 'space-between';
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}
.map-container {
  height: 400px;
  }
   
  .sidebar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #ffff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
  }
.nav-item
  {
     border-radius: 10px;
    padding: 5%;  
    height: 90%;
    background-color: #002909;
    list-style-type: none;
    margin: 1%;
    text-align: center;
    white-space: noWrap;
    transition: 0.3s;
  }
.nav-item:hover{
  background-color:#790a23;
  margin-bottom: 4%;
}
.break{
  flex-basis: 100%;
  height: 0;
}
.nav-link{
  width: 100%;
  height: 100%;
  font-family: sageModern, open-sans, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: white;
  font-size: 10;
  text-decoration: none;
  filter: drop-Shadow(2px 2px 2px #96C1AE);
  transition: 0.3s;
}

.nav-item:hover > a{
  color: #3D3D3D;
  filter: drop-Shadow(2px 2px 2px #707070)
}

.active > a{
  color: #3D3D3D;
  filter: drop-Shadow(2px 2px 2px #707070)
}

.active{
  background-color:#849f62;
  box-shadow: 2px 2px 10px #A07676;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #aaa;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(154, 168, 151);
}

.custom-shape-divider-top-1662483222 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1662483222 svg {
  position: relative;
  display: block;
  width: calc(129% + 1.3px);
  height: 40px;
}

.custom-shape-divider-top-1662483222 .shape-fill {
  fill: #C1D5EF;
}

/** For mobile devices **/
@media (max-width: 767px) {
  .custom-shape-divider-top-1662483222 svg {
      width: calc(129% + 1.3px);
      height: 50px;
  }
}
.custom-shape-divider-bottom-1662483860 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1662483860 svg {
  position: relative;
  display: block;
  width: calc(129% + 1.3px);
  height: 40px;
}

.custom-shape-divider-bottom-1662483860 .shape-fill {
  fill: #2D4088;
}

/** For mobile devices **/
@media (max-width: 767px) {
  .custom-shape-divider-bottom-1662483860 svg {
      width: calc(100% + 1.3px);
      height: 129px;
  }
}

/* KEEP */

.custom-shape-divider-top-1685323273 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1685323273 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 140px;
  transform: rotateY(180deg);
}

.custom-shape-divider-top-1685323273 .shape-fill {
  fill: #849F62;
}